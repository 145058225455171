import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "sonner";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: "8px",
  boxShadow: 24,
  p: "8px 24px 24px 24px",
};

const PluginChargerModal = ({ open, handleClose, deviceDetails }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { deviceId, countryCode } = useParams();

  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2);

  const handleProceed = () => {
    if (
      deviceDetails?.connectorType === "Type 2" &&
      deviceDetails?.status === "Available"
    ) {
      toast.error("Please connect the charger to your vehicle");
    } else navigate(`/verification/${countryCode}/${deviceId}`);
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  mr: "-16px",
                }}
                onClick={handleClose}
              >
                <span
                  style={{
                    fontWeight: 200,
                    fontSize: "32px",
                    color: "#8A0000",
                  }}
                  className="material-symbols-outlined"
                >
                  cancel
                </span>
              </Box>
              <Box
                sx={{
                  mt: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  style={{ width: "250px" }}
                  src="/images/plugin.png"
                  alt=""
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                  mt: "32px",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontSize={"32px"}
                  fontFamily={"mySecondFont"}
                  color="#161616"
                  textTransform={"capitalize"}
                  textAlign={"center"}
                  lineHeight={"40px"}
                >
                  Please plugin
                  <br /> the Connector!
                </Typography>
                <Typography
                  fontSize={"16px"}
                  fontFamily={"myThirdFont"}
                  color="#2F2F2F"
                  textAlign={"center"}
                  lineHeight={"24px"}
                >
                  Please ensure that the charging gun is securely connected to
                  the vehicle before starting the charging
                </Typography>
              </Box>

              {/* Buttons */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "4px",
                  mt: "56px",
                }}
              >
                <Button
                  sx={{
                    background:
                      "linear-gradient(94deg, #FF7B52 -20.04%, #E50000 122.31%)",
                    borderRadius: "8px",
                    padding: "12px 24px",
                    width: "100%",
                    fontFamily: "mySecondFont",
                    color: "#fcfcfc",
                    letterSpacing: "1.28px",
                  }}
                  onClick={handleProceed}
                >
                  Proceed
                </Button>
                <Button
                  sx={{
                    background: "transparent",
                    borderRadius: "8px",
                    padding: "12px 24px",
                    width: "100%",
                    fontFamily: "mySecondFont",
                    color: "#222",
                    letterSpacing: "1.28px",
                  }}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </Box>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default PluginChargerModal;
