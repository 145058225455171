const BASE_URL = "https://backend.1charging.com";
// const BASE_URL = "http://10.5.48.182:3000";
// const BASE_URL = "http://localhost:3000";

export const endPoints = {
  // All - Api
  getDeviceDetails: BASE_URL + "/api/v4/charging-point-status",
  sendOTP: BASE_URL + "/api/v4/users/send-otp",
  resendOTP: BASE_URL + "/api/v4/users/resend-otp",
  verifyOTP: BASE_URL + "/api/v4/users/verify-otp",
  paytmInit: BASE_URL + "/api/direct-payment",

  // payment gateways
  paytmGateway:
    "http://backend.1charging.com/api/direct-payment/create-intent",
  stripeGateway:
    "https://securegw.paytm.in/merchantpgpui/checkoutjs/merchants",
};
