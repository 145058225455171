import { Box, Typography } from "@mui/material";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const location = useLocation();

  const countryCodeName = location.pathname
    .split("/")
    .find((part) => part.length === 2);

  return (
    <Box
      className="page"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "12px",
        width: "100%",
        padding: "16px",
        mt: "4px",
        background: "#fff",
      }}
    >
      <Typography color="#2f2f2f" fontSize={"14px"}>
        Need help?
      </Typography>
      <Box sx={{ display: "flex", gap: "12px", alignItems: "center" }}>
        {countryCodeName === "IN" ? (
          <a href="tel:01143146973">
            <Box
              sx={{
                background: "linear-gradient(180deg, #E9E9E9 0%, #F8F8F8 100%)",
                width: "48px",
                height: "48px",
                borderRadius: "48px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PhoneIcon sx={{ color: "#292D33", fontSize: "16px" }} />
            </Box>
          </a>
        ) : (
          ""
        )}

        <a href="mailto:support@1charging.com">
          <Box
            sx={{
              background: "linear-gradient(180deg, #E9E9E9 0%, #F8F8F8 100%)",
              width: "48px",
              height: "48px",
              borderRadius: "48px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <EmailIcon sx={{ color: "#292D33", fontSize: "16px" }} />
          </Box>
        </a>
        <Box
          sx={{
            background: "linear-gradient(180deg, #E9E9E9 0%, #F8F8F8 100%)",
            width: "48px",
            height: "48px",
            borderRadius: "48px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WhatsAppIcon sx={{ color: "#292D33", fontSize: "16px" }} />
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
